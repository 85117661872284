import { useAuth0 } from '@auth0/auth0-react';
import type z from 'zod';
import { type FetchConfig, fetchApi } from '~/api/fetch';

export type FetchMethod<RequestBody, ResponseType> = (
  url: string,
  schema: z.ZodType<ResponseType>,
  config?: FetchConfig<RequestBody>,
) => Promise<ResponseType>;

export function useFetch() {
  const { getAccessTokenSilently } = useAuth0();

  async function fetchData<RequestBody, ResponseType>(
    url: string,
    schema: z.ZodType<ResponseType>,
    config?: FetchConfig<RequestBody>,
  ) {
    const token = await getAccessTokenSilently();
    const response = await fetchApi<RequestBody, ResponseType>(url, {
      ...config,
      options: {
        ...config?.options,
        headers: {
          Authorization: `Bearer ${token}`,
          ...config?.options?.headers,
        },
      },
    });

    const validatedResponse = schema.safeParse(response);

    if (!validatedResponse.success) {
      throw new Error(`Invalid response schema: ${validatedResponse.error}`);
    }

    return validatedResponse.data;
  }

  return { fetchData };
}
